// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';



/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#blocIntro {
    background: url("../images/accueil_section01_bg.jpg") no-repeat;
    display: flex;
    justify-content: center;
    min-height: 608px;
    padding: 120px;
    .left-side {
        width: 43%;
        margin-right: 15%;
    }
    .right-side {
        width: 44%;
    }
    p {
        font-weight: bold;
        font-size: $font-size-28;
        margin: 0;
        line-height: 1.5;
        color: #fff;
    }
    p.last {
        padding-top: 65px;
    }
}


.section-burst {
    display: flex;
    justify-content: space-around;
    padding: 50px 5%;
    .burst {
        background: #ef0010;
        width: 30%;
        transition: .3s;
        a {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        height: calc(100vw * 250 / 1920);
        p {
            text-align: center;
            font-weight: 900;
            font-size: $font-size-30;
            line-height: 1.3;
            color: #fff;
            text-transform: uppercase;
        }
        &:hover {
            background: #282cfc;
        }
    }
}
#blocParallax {
    background: url(../images/accueil_section02_bg.jpg) no-repeat;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;


    .section-texte {
        display: flex;
        padding: 50px 125px 100px 125px;
        h3 {
            color: #fff;
            font-weight: 900;
            padding-bottom: 15px;
            font-size: $font-size-42;
            font-style: italic;
            text-transform: initial;
        }
        p {
            color: #fff;
            font-size: $font-size-28;
            font-weight: 500;
            margin: 0;
            line-height: 1.5;
        }
        .left-side {
            width: 80%;
            h3 {
                padding-bottom: 10px;
            }
        }
        .border {
            border: 5px solid #fff;
            margin-right: 10%;
            background: #fff;
        }
    }
}

section.section-image-text {
    div.container-fluid.row {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: stretch;
        justify-content: center;
        & > div {
            min-height: 450px;
            transition: .3s;
            width: 50%;
        }
        @media screen and (max-width: $size-xs-max){
            flex-direction: column;
            transition: .3s;
            & > div {
                transition: .3s;
                width: 100%;
            }
        }
    }

    div.image-side {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 5%;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
            color: $color-white;
            margin: 0;
            line-height: 1.5;
            padding-bottom: 50px;
        }
    }

    div.text-side {
        padding: 5%;
        display: flex;
        align-items: center;
        justify-content: center;
        h3 {
            color: $color-black;
            font-weight: 900;
            padding-bottom: 25px;
            font-size: $font-size-42;
            font-style: italic;
            text-transform: initial;
        }
        p {
            color: $color-black;
            font-size: $font-size-28;
            font-weight: bold;
            margin: 0;
            line-height: 1.5;
            padding-bottom: 50px;
        }
        .btnEbay {
            background: #161afc;
            transition: 0.3s;
            a.bouton {
                display: flex;
                justify-content: space-around;
                padding: 50px 0 0 0;
                .texte {
                    h3 {
                        color: #fff;
                        padding-bottom: 0;
                    }
                    p {
                        color: #fff;
                        line-height: 1;
                        font-style: italic;
                        span {
                            font-size: $font-size-42;
                            font-weight: 900;
                            font-style: italic;
                        }
                    }
                }
                .logo {
                    margin-top: -25px;
                }
            }
            &:hover {
                background: #ef0010;
                transition: 0.3s;
            }
        }
    }
}

.ombre {
    box-shadow: 0 8px 8px -6px black;
    position: relative;
    z-index: 1;
    padding-bottom: 17px;
    margin-top: -17px;
}
.main-content {
    position: relative;
    background: #f6f6f6;
    padding: 50px 0;
    border-top: 2px solid #000;
    .owl-carousel {
        padding: 0 30px;
        margin: auto;
    }
    .owl-theme {
        .custom-nav {
            position: absolute;
            top: 20%;
            left: 0;
            right: 0;
            .owl-prev, .owl-next {
                position: absolute;
                height: 100px;
                color: inherit;
                background: none;
                border: none;
                z-index: 100;
                i {
                    font-size: 2.5rem;
                    color: #000;
                }
            }
            .owl-prev {
                left: 0;
                top: 25px;
            }
            .owl-next {
                right: 0;
                top: 25px;
            }
        }
    }
    .owl-carousel .owl-item img {
      //  width: 80%;
        margin: auto;
    }
}


 .burst-a-propos {
    background-color: $color-black;
 }

section.section-produit-promo {
  background-color: #3f4147;
  padding: 60px 0;
    div.container-fluid.row {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
        & > div {
            width: 50%;
        }
    }
    div.left-side {
      p {
        font-weight: 400;
        color: $color-white
      }
    }
    div.right-side {
        background-color: $color-white;
        border: 5px solid #ef0010;
        div.img {flex: 1;}
        div.text-wrapper {
            padding: 20px;
            flex: 2;
            h3 {
              color: #ef0010;
              font-size: $font-size-28;
            }
            h4 {
              font-weight: 700;
              padding: 15px 0;
              font-size: $font-size-24;
            }
            p {
                font-size: $font-size-24;
                line-height: 1.5;
            }
        }
    }
    @media screen and (max-width: $size-xs-max){
        div.container-fluid.row {
            & > div {
                width: 100%;
            }
        }
    }
}


section.section-01-coordonnees {
    background-color: #ef0010;
    padding: 50px 10px;
    div.container-fluid.row {
        width: 90%;
        max-width: 1600px;
        margin: auto;
    }
    p {
      font-size: $font-size-24;
      color: $color-white;
      line-height: 1.5;
    }
}
section.section-02-coordonnees {
    padding: 0;
    background-color: #161afc;
    div.container-fluid.row {
      margin: 0;
      padding: 0;
        & > div {
            width: 50%;
        }
    }
    p {
        font-size: $font-size-24;
        color: $color-white;
        font-weight: 400;
        line-height: 1.5;
        &.horaire {
            display: flex;
            span {
                width: 190px;
            }
            @media screen and (max-width: $size-sm-max){
                flex-direction: column;
            }
        }
    }
    div.left-side {
        padding: 50px 10px 50px 50px;
        div.icon {
            width: 100px;
            i {
                font-size: 60px;
                color: $color-white;
            }
        }
    }
    @media screen and (max-width: $size-xs-max){
        div.container-fluid.row {
          flex-direction: column;
            & > div {
                width: 100%;
            }
        }
        div.left-side {
            padding: 50px 10px 50px 10px;
            div.icon {
                width: 70px;
            }
        }
        .label-wrapper, .input-wrapper {
            width: 100%;
        }
    }
}
/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1880px) {

  #blocIntro {
      padding: 130px 110px;
  }
}

@media screen and (max-width: 1645px) {

    #blocIntro {
        padding: 110px 100px;
    }
    #blocIntro p.last {
        padding-top: 50px;
    }
    #blocParallax .section-texte .right-side {
        width: 65%;
    }
    #blocParallax .section-texte p br {
        display: none;
    }
    #blocParallax .section-texte .left-side {
        width: 65%;
        margin-right: 10%;
    }
}

@media screen and (max-width: 1550px) {

    .slideshow-wrap .caption h3 br {
        display: none;
    }
    .slideshow-wrap .caption h3 {
        font-size: 2rem;
    }
    .slideshow-wrap .caption {
        top: 82%;
        left: 46%;
    }
    .section-burst .burst p {
        font-size: 1.2rem;
    }
    #blocParallax .section-texte {
        padding: 50px 100px 100px 100px;
    }
    #blocParallax .section-texte .border {
        margin-right: 7%;
    }
    #blocParallax .section-texte p, section.section-image-text div.text-side p{
        font-size: 1.2rem;
    }
    section.section-image-text div.text-side h3 {
        font-size: 2rem;
    }
    .main-content .owl-carousel .owl-item img {
        //width: 50%;
    }
}

@media screen and (max-width: 1485px) {

    .slideshow-wrap .caption {
        top: 78%;
        left: 45%;
    }
    #blocIntro {
        padding: 90px 100px;
    }
}

@media screen and (max-width: 1380px) {

    nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > a {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li div {
        font-size: 1.1rem;
    }
    #blocIntro {
        padding: 90px 65px;
        background-position: center;
    }
    .section-burst .burst p {
        font-size: 1.1rem;
    }
    section.section-image-text div.text-side .btnEbay a.bouton .logo {
        margin-top: 0;
        width: 35%;
    }
}

@media screen and (max-width: $size-md-max) {

    nav.module-menu .menu-navbar {
        height: 115px;
    }
    nav.module-menu .menu-navbar .wrapper.right .main-menu-top, nav.module-menu .menu-navbar .wrapper.right .main-menu {
        height: 45%;
    }
    nav.module-menu .menu-navbar .wrapper.left .logo {
        max-width: 370px;
    }
    nav.module-menu .menu-navbar.nav-invisible {
        top: -115px;
    }
    #blocParallax .section-texte .left-side {
        margin-right: 8%;
    }
}

@media screen and (max-width: 1024px) {

    nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li div {
        font-size: 0.9rem;
    }
    .slideshow-wrap .caption {
        top: 71%;
        left: 45%;
    }
    .section-burst .burst p {
        font-size: 1.2rem;
    }
    #blocIntro {
        padding: 65px 55px;
    }
    .section-burst {
        padding: 50px 3%;
    }
    .section-burst .burst p {
        font-size: 0.9rem;
    }
    #blocParallax .section-texte {
        padding: 50px 50px 100px 50px;
    }
    section.section-image-text div.text-side h3 {
        font-size: 1.8rem;
    }
    #blocIntro p {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: $size-sm-max) {

    .slideshow-wrap .caption h3 {
        font-size: 1.7rem;
    }
    .slideshow-wrap .caption {
        top: 69%;
        left: 45%;
    }
    .section-burst .burst p {
        font-size: 1rem;
    }
    #blocIntro {
        flex-direction: column;
        padding: 50px 5%;
        background: #161afc;
        min-height: auto;
    }
    #blocIntro .right-side {
        width: 100%;
    }
    #blocIntro .left-side {
        width: 100%;
        margin-right: 0;
        padding-bottom: 25px;
    }
    #blocIntro p.last {
        padding-top: 25px;
    }
    .section-burst .burst p {
        font-size: 0.8rem;
    }
    #blocParallax .section-texte {
        padding: 50px 3%;
        flex-direction: column;
    }
    #blocParallax .section-texte .left-side {
        width: 100%;
        padding-bottom: 20px;
        margin-right: 0;
    }
    #blocParallax .section-texte p br, section.section-image-text div.text-side p br {
        display: none;
    }
    #blocParallax .section-texte .left-side {
        width: 100%;
        padding-bottom: 20px;
    }
    #blocParallax .section-texte .border {
        margin-right: 0;
        margin-bottom: 20px;
        width: 75%;
    }
    section.section-image-text div.text-side .btnEbay a.bouton {
        padding: 20px;
        flex-direction: column;
        text-align: center;
    }
    section.section-image-text div.text-side .btnEbay a.bouton .logo {
        width: 65%;
        margin: auto;
    }
    section.section-image-text div.text-side .text-box .texte {
        margin-bottom: -40px;
    }
    #blocParallax .section-texte .right-side p {
        padding-bottom: 10px;
    }
    #blocParallax .section-texte .right-side {
        width: 100%;
    }
}

@media screen and (max-width: $size-xs-max) {

    nav.module-menu .menu-navbar .wrapper.left .logo {
        max-width: 265px;
    }
    .section-burst {
        flex-direction: column;
        padding: 50px 5% 25px 5%;
    }
    .section-burst .burst {
        width: 100%;
        height: calc(250vw * 250 / 1920);
        margin-bottom: 15px;
    }
    .section-burst .burst p {
        font-size: 1.2rem;
    }
    #blocParallax .section-texte {
        padding: 25px 3% 50px 3%;
    }
    section.section-image-text div.text-side .btnEbay {
        width: 65%;
        margin: auto;
    }
    .main-content .owl-carousel .owl-item img {
        //width: 60%;
    }
    .main-content .owl-theme .custom-nav .owl-prev, .main-content .owl-theme .custom-nav .owl-next {
        top: 30px;
    }
}

@media screen and (max-width: 360px) {

    section.section-image-text div.text-side .btnEbay {
        width: 85%;
        margin: auto;
    }
}
