.section-text-image {
    &.bg-brown {
      background-color: #3f4147;

    }
    .image {
        padding: 0;
        img {
            float: right;
        }
    }
    .text {
        padding-top: 7vh;
        p {
            font-size: 20px;
        }
        blockquote, .cite {
            text-align: right;
            @extend .font-2-normal;
            font-size: 1.2vw;
            font-size: 24px;
        }
        blockquote {
            line-height: 1.7;
            padding: 2vh 0 2vh 20%;
            border-left: none;
            border-top: 1px solid $color-white;
            border-bottom: 1px solid $color-white;
            color: $color-primary;
            strong {
                @extend .font-1-normal;
                text-transform: uppercase;
                font-weight: 300;
                color: $color-white;
            }
        }
        .cite {
            color: $color-white;
            line-height: 1.1;
            margin: 2vh 0 7vh 0;
            small {
                font-size: 13px;
                @extend .font-1-normal;
                font-weight: 300;
            }
        }
        blockquote + p {
            margin-top: 5vh;
        }
        p {
            margin: 0 0 5vh 0;
            text-align: justify;
            color: $color-white;
        }
    }
    @media screen and (max-width: $size-sm-max){
        .image {
            padding: 0;
            img {
                float: none;
                margin: auto;
            }
        }
    }
}
